import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useLocalStorage from "react-use-localstorage";
import { useRecoilState } from "recoil";
import { WIKI_URL } from "../../../config/api";
import { errorState } from "../../../recoil";
import AppBar from "../../parts/AppBar";
import WikiSideBar from "../../parts/WikiSideBar";
import HelpIcon from "@material-ui/icons/Help"
import ReactMarkdown from "react-markdown";
const gfm = require('remark-gfm')

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: 96,
        marginLeft: 290
    }
}));


export default ({match}) => {
    const [wikiText, setWikiText] = useState('');
    const [error, setError] = useRecoilState(errorState);
    const [token, setToken] = useLocalStorage('token', null);
    const classes = useStyles();
    const [filePaths, setFilePaths] = useState([]);

    useEffect(() => {
        let wikiName = match.params.filePath === undefined ? 'Welcome' : match.params.filePath;
        document.title = `${wikiName} | Wiki Admortgage Forms Bot`
    }, [])

    useEffect(() => {
        fetch(WIKI_URL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                if (!res.ok) throw Error(res.status);
                return res.json();
            })
            .then(res => setFilePaths(res))
            .catch(err => {
                setError(err.message);
                console.log(err);
            })
    }, [])

    useEffect(() => {
        let filePath = match.params.filePath;
        if (!match.params.filePath) {
            filePath = "index";
        }

        fetch(WIKI_URL + `${filePath}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                if (!res.ok) throw Error(res.status);
                return res.json()
            })
            .then(res => {
                setWikiText(res.text);
            })
            .catch(err => {
                console.log(err);
                setError('Failed to fetch text!');
            })
    }, [])
    return (
        <div>
            <AppBar />
            <WikiSideBar 
                activeTab={match.params.filePath === undefined ? 'Welcome' : match.params.filePath} 
                tabs={filePaths.map(path => ({
                    name: path == 'index' ? 'Welcome' : path,
                    url: path == 'index' ? `/wiki` : `/wiki/${path}` ,
                    icon: <HelpIcon />
                }))}/>
            <div className={classes.content}>
                <ReactMarkdown plugins={[gfm]}>{wikiText}</ReactMarkdown>
            </div>
            
        </div>
    )
}