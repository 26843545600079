import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    listItem: {
        textDecoration: null
    },
    logoutButton: {
        position: "absolute",
        right: 20,
        color: "white"
    }
}));


export default ({activeTab}) => {
    const classes = useStyles();

    useEffect(() => {
        document.title = 'Forms list | Admortgage Forms Bot'
    }, [])

    return (
        <Drawer
            variant="permanent"
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Toolbar />
            <div className={classes.drawerContainer}>
                <List>
                    <ListItem button disabled={activeTab === 'forms'} key={"forms"} component="a" href={activeTab !== 'forms' ? '/forms' : null}>
                        <ListItemIcon><ListAltIcon /></ListItemIcon>
                        <ListItemText primary={"Forms list"} />
                    </ListItem>
                    <ListItem button disabled={activeTab === 'settings'} key={"settings"} component="a" href={activeTab !== 'settings' ? "/settings" : null}>
                        <ListItemIcon><SettingsIcon /></ListItemIcon>
                        <ListItemText primary={"Settings"} className={classes.listItem} />
                    </ListItem>
                    <ListItem button component="a" href="/wiki">
                        <ListItemIcon><HelpIcon /></ListItemIcon>
                        <ListItemText primary={"Wiki"} className={classes.listItem} />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    )
}