import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import FormsTable from "../../parts/FormsTable";
import useLocalStorage from "react-use-localstorage";
import AppBar from "../../parts/AppBar";
import AppSideBar from "../../parts/AppSideBar";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    listItem: {
        textDecoration: null
    },
    logoutButton: {
        position: "absolute",
        right: 20,
        color: "white"
    }
}));


export default () => {
    const classes = useStyles();
    const [token, setToken] = useLocalStorage('token', null);

    useEffect(() => {
        document.title = 'Forms list | Admortgage Forms Bot'
    }, [])

    return (
        <div className={classes.root}>
            <AppBar />
            <AppSideBar activeTab="forms" />
            <main className={classes.content}>
                <Toolbar />
                <FormsTable />
            </main>
        </div>
    )
}