import { AppBar, Button, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    logoutButton: {
        position: "absolute",
        right: 20,
        color: "white"
    }
}));


export default () => {
    const classes = useStyles();

    useEffect(() => {
        document.title = 'Forms list | Admortgage Forms Bot'
    }, [])

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Link to="/" style={{
                    textDecoration: "none",
                    color: "white"
                }}>
                    <Typography variant="h6" noWrap>
                        Admortgage bots admin panel
                    </Typography>
                </Link>
                <Button
                    className={classes.logoutButton}
                    onClick={e => {
                        window.localStorage.removeItem('token');
                        window.location.href = '/';
                    }}
                >
                    LOGOUT
                </Button>
            </Toolbar>
        </AppBar>
    )
}