import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import { FORMS_URL } from "../../../config/api";
import useLocalStorage from "react-use-localstorage";
import { useRecoilState } from "recoil";
import { errorState, successState, formsListState } from "../../../recoil";

const useClasses = makeStyles((theme) => ({
    // addButton: {
    //     marginLeft: "auto",
    //     marginRight: "auto",
    //     display: "block"
    // },
    // emailConfigsTitle: {
    //     fontSize: "1.25rem",
    //     color: "rgba(0, 0, 0, 0.87)",
    //     marginTop: 36
    // },
    // emailConfigUnit: {
    //     padding: "20px 25px",
    //     marginBottom: 20
    // },
    // emailConfigToolbar: {
    //     fontSize: "1.25rem",
    //     padding: 0,
    //     minHeight: 26,
    //     height: 26,
    //     marginBottom: 15
    // },
    // dialog: {
    //     maxWidth: "960px",
    //     marginLeft: "auto",
    //     marginRight: "auto"
    // },
    // formFieldsTitle: {
    //     fontSize: "1.2rem",
    //     color: "rgba(0, 0, 0, 0.87)",
    //     marginTop: 36
    // },
    // commonTitle: {
    //     fontSize: "1.2rem",
    //     color: "rgba(0, 0, 0, 0.87)",
    //     flex: '1 1 100%',
    // },
    // formFieldToolbar: {
    //     minHeight: 26,
    //     height: 26,
    //     padding: 0
    // },
}));

export default ({ open, handleClose, formsToDelete, onDeleted }) => {
    const classes = useClasses();
    const [token, setToken] = useLocalStorage('token', null);
    const [formId, setFormId] = useState(null);
    const [formPrintFileId, setFormPrintFileId] = useState(null);
    const [name, setName] = useState('');
    const [emailConfig, setEmailConfig] = useState([]);
    const [error, setError] = useRecoilState(errorState);
    const [success, setSuccess] = useRecoilState(successState);
    const [formsList, setFormsList] = useRecoilState(formsListState);

    const closeDialog = () => {
        handleClose();
        console.log('closing')
        setFormId(null);
        setFormPrintFileId(null);
        setName('');
        setEmailConfig([]);
    }

    const deleteForms = async () => {
        let deletedForms = [];
        let errorOccurred = false;
        for (let i = 0; i < formsToDelete.length; i++) {
            const form = formsToDelete[i];
                await fetch(FORMS_URL, {
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        'id': form.id
                    })
                })
                    .then(res => {
                        if (!res.ok) throw Error;
                        return res.json()
                    })
                    .then(res => {
                        deletedForms = [...deletedForms, form.id];
                    })
                    .catch(err => {
                        console.log(err);
                        errorOccurred = true;
                        setError('Failed to delete forms!');
                    })
        }
        setFormsList(formsList.filter(f => !deletedForms.includes(f.id)))
        if (onDeleted) {
            onDeleted();
        }
        closeDialog();
        if (!errorOccurred)
            setSuccess("Successfully deleted!");
    }

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" className={classes.dialog}>
            <DialogTitle id="form-dialog-title">New form</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Do you really want to delete forms: 
                    {formsToDelete.map(form => (
                        <>
                            <br/>
                            {form.id}. {form.name}
                        </>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={deleteForms} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}