import { atom } from "recoil";

export const successState = atom({
    key: 'successState',
    default: null
});

export const errorState = atom({
    key: 'errorState',
    default: null
});

export const formsListState = atom({
    key: 'formListState',
    default: []
});;