import "./index.scss";
import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useLocalStorage from "react-use-localstorage";
import { LOGIN_URL } from "../../../config/api";
import { useRecoilState } from "recoil";
import { errorState } from "../../../recoil";

export default () => {
    const [token, setToken] = useLocalStorage('token', null);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useRecoilState(errorState);

    useEffect(() => {
        document.title = 'Login | Admortgage Forms Bot'
    }, [])

    const signIn = (e) => {
        e.preventDefault();

        fetch(LOGIN_URL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'login': login,
                'password': password
            })
        })
            .then(res => {
                if (!res.ok) throw Error;
                return res.json();
            })
            .then(res => {
                setToken(res.token);
                window.location.href = "/";
            })
            .catch(err => {
                console.log(err);
                setError('Failed to sign in!')
            })
    }

    return (
        <div>
            <form 
                className="login-page__form" 
                onSubmit={signIn}
            >
                <div className="login-page__form-row">
                    <TextField
                        required
                        label="Login"
                        value={login}
                        style={{
                            width: "360px"
                        }}
                        onChange={e => setLogin(e.target.value)}
                    />
                </div>
                <div className="login-page__form-row">
                    <TextField
                        required
                        type="password"
                        label="Password"
                        value={password}
                        style={{
                            width: "360px"
                        }}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    SIGN IN
                </Button>
            </form>
        </div>
    )
}