import React, { useEffect } from "react";

export default () => {
    useEffect(() => {
        document.title = 'Not found | Admortgage Forms Bot'
    }, [])
    return (
        <div>
            Page not found
        </div>
    )
}