import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import { FORMS_URL } from "../../../config/api";
import useLocalStorage from "react-use-localstorage";
import { useRecoilState } from "recoil";
import { errorState, successState, formsListState } from "../../../recoil";

const useClasses = makeStyles((theme) => ({
    addButton: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "block"
    },
    emailConfigsTitle: {
        fontSize: "1.25rem",
        color: "rgba(0, 0, 0, 0.87)",
        marginTop: 36
    },
    emailConfigUnit: {
        padding: "20px 25px",
        marginBottom: 20
    },
    emailConfigToolbar: {
        fontSize: "1.25rem",
        padding: 0,
        minHeight: 26,
        height: 26,
        marginBottom: 15
    },
    dialog: {
        maxWidth: "960px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    formFieldsTitle: {
        fontSize: "1.2rem",
        color: "rgba(0, 0, 0, 0.87)",
        marginTop: 36
    },
    commonTitle: {
        fontSize: "1.2rem",
        color: "rgba(0, 0, 0, 0.87)",
        flex: '1 1 100%',
    },
    formFieldToolbar: {
        minHeight: 26,
        height: 26,
        padding: 0
    },
    closeTaskCheck: {
        marginTop: 10
    },
    approvalChoiceSelect: {
        marginTop: 10
    }
}));

export default ({open, handleClose, id=null}) => {
    const classes = useClasses();
    const [token, setToken] = useLocalStorage('token', null);
    const [formId, setFormId] = useState(null);
    const [formPrintFileId, setFormPrintFileId] = useState(null);
    const [SMTPLogin, setSMTPLogin] = useState('');
    const [SMTPPassword, setSMTPPassword] = useState('');
    const [name, setName] = useState('');
    const [emailConfig, setEmailConfig] = useState([]);
    const [error, setError] = useRecoilState(errorState);
    const [success, setSuccess] = useRecoilState(successState);
    const [formsList, setFormsList] = useRecoilState(formsListState);

    useEffect(() => {
        if (id !== null) {
            formsList.forEach(form => {
                if (form.id === id) {
                    setFormId(form.form_id);
                    setFormPrintFileId(form.form_print_file_id);
                    setName(form.name);
                    setEmailConfig(form.email_config);
                    setSMTPLogin(form.smtp_login)
                }
            })
        }
    }, [open, id])

    const closeDialog = () => {
        handleClose();
        console.log('closing')
        setFormId(null);
        setFormPrintFileId(null);
        setSMTPLogin('');
        setSMTPPassword('');
        setName('');
        setEmailConfig([]);
    }

    const saveNewForm = () => {
        fetch(FORMS_URL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                'form_id': formId,
                'form_print_file_id': formPrintFileId,
                'smtp_login': SMTPLogin,
                'smtp_password': SMTPPassword,
                'name': name,
                'email_config': emailConfig
            })
        })
            .then(res => {
                if (!res.ok) throw Error;
                return res.json()
            })
            .then(res => {
                closeDialog();
                setFormsList(formsList.concat({
                    'id': res.id,
                    'form_id': formId,
                    'form_print_file_id': formPrintFileId,
                    'smtp_login': SMTPLogin,
                    'name': name,
                    'email_config': emailConfig
                }))
                setSuccess("Successfully saved!");
            })
            .catch(err => {
                console.log(err);
                setError('Failed to save form!');
            })
    }

    const updateForm = () => {
        let formObject = {
            'id': id,
            'form_id': formId,
            'form_print_file_id': formPrintFileId,
            'smtp_login': SMTPLogin,
            'name': name,
            'email_config': emailConfig
        }
        if (SMTPPassword !== '') {
            formObject.smtp_password = SMTPPassword;
        }
        fetch(FORMS_URL, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(formObject)
        })
            .then(res => {
                if (!res.ok) throw Error;
                return res.json()
            })
            .then(res => {
                setFormsList(formsList.map(form => form.id === id ? ({
                    'id': res.id,
                    'form_id': formId,
                    'form_print_file_id': formPrintFileId,
                    'smtp_login': SMTPLogin,
                    'name': name,
                    'email_config': emailConfig
                }) : form));
                closeDialog();
                setSuccess("Successfully saved!");
            })
            .catch(err => {
                console.log(err);
                setError('Failed to save form!');
            })
    }

    return (
        <Dialog fullScreen open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" className={classes.dialog}>
            <DialogTitle id="form-dialog-title">New form</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To add a new form bot enter all the required information below
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="formId"
                    label="Form ID"
                    type="number"
                    fullWidth
                    value={formId}
                    onChange={e => setFormId(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="formPrintFileId"
                    label="Form File ID"
                    type="number"
                    fullWidth
                    value={formPrintFileId}
                    onChange={e => setFormPrintFileId(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="SMTPLogin"
                    label="Office 265 Login"
                    type="text"
                    fullWidth
                    value={SMTPLogin}
                    onChange={e => setSMTPLogin(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="SMTPPassword"
                    label="Office 265 Password"
                    type="password"
                    fullWidth
                    value={SMTPPassword}
                    onChange={e => setSMTPPassword(e.target.value)}
                />
                <DialogContentText className={classes.emailConfigsTitle}>
                    Email configuration
                </DialogContentText>
                <DialogContentText>
                    Here is the list of email configurations that will be used to send reports to the reporters after approval or rejection
                </DialogContentText>
                {emailConfig.map((config, index) => (
                    <Paper className={classes.emailConfigUnit}>
                        <Toolbar className={classes.emailConfigToolbar}>
                            <Typography className={classes.commonTitle} color="inherit" variant="subtitle1" component="div">
                                Config unit
                            </Typography>
                            <Tooltip title="Delete">
                                <IconButton
                                    aria-label="delete"
                                    onClick={e => {
                                        setEmailConfig(emailConfig.filter((conf, i) => i !== index))
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                        <Typography className={classes.commonTitle} color="inherit" variant="subtitle1" component="div">
                            Common
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="email-config-unit-type-label">Type</InputLabel>
                            <Select
                                labelId="email-config-unit-type-label"
                                margin="dense"
                                id="email-config-unit-type"
                                fullWidth
                                value={config['type']}
                                onChange={e => {
                                    setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                        ...conf,
                                        type: e.target.value
                                    }) : conf))
                                }}
                            >
                                <MenuItem value={'approved'}>Approved</MenuItem>
                                <MenuItem value={'rejected'}>Rejected</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.approvalChoiceSelect}>
                            <InputLabel id="email-config-unit-approval-choice-label">Approval choice</InputLabel>
                            <Select
                                labelId="email-config-unit-approval-choice-label"
                                margin="dense"
                                id="email-config-unit-type"
                                fullWidth
                                value={config['approval_choice']}
                                onChange={e => {
                                    setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                        ...conf,
                                        approval_choice: e.target.value
                                    }) : conf))
                                }}
                            >
                                <MenuItem value={'acknowledged'}>Acknowledged</MenuItem>
                                <MenuItem value={'approved'}>Approved</MenuItem>
                                <MenuItem value={'rejected'}>Rejected</MenuItem>
                                <MenuItem value={'revoked'}>Revoked</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            className={classes.closeTaskCheck}
                            control={
                                <Checkbox 
                                    checked={config['close_task']} 
                                    onChange={e => {
                                        setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                            ...conf,
                                            close_task: e.target.checked
                                        }) : conf))
                                    }} 
                                    name="close_task" 
                                />
                            }
                            label="Close task after processing"
                        />
                        <TextField
                            multiline
                            margin="dense"
                            id="subject"
                            label="Subject"
                            type="text"
                            fullWidth
                            value={config['subject']}
                            onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                ...conf,
                                subject: e.target.value
                            }) : conf))}
                        />
                        <TextField
                            multiline
                            margin="dense"
                            id="text"
                            label="Email text"
                            type="text"
                            fullWidth
                            value={config['text']}
                            onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                ...conf,
                                text: e.target.value
                            }) : conf))}
                        />
                        <TextField
                            multiline
                            margin="dense"
                            id="form_cc_email"
                            label="Form CC Email"
                            type="text"
                            fullWidth
                            value={config['form_cc_email']}
                            onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                ...conf,
                                form_cc_email: e.target.value
                            }) : conf))}
                        />
                        <TextField
                            multiline
                            margin="dense"
                            id="sender_email_field__field_name"
                            label="Form filler email field"
                            type="text"
                            fullWidth
                            value={config['sender_email_field']['field_name']}
                            onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                ...conf,
                                sender_email_field: {
                                    ...conf['sender_email_field'],
                                    field_name: e.target.value
                                }
                            }) : conf))}
                        />
                        {/* <TextField
                            multiline
                            margin="dense"
                            id="sender_email_field__group_name"
                            label="Sender email field. Group name"
                            type="text"
                            fullWidth
                            value={config['sender_email_field']['group_name']}
                            onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                ...conf,
                                sender_email_field: {
                                    ...conf['sender_email_field'],
                                    group_name: e.target.value
                                }
                            }) : conf))}
                        /> */}
                        <DialogContentText className={classes.formFieldsTitle}>
                            Form fields
                        </DialogContentText>
                        <DialogContentText>
                            Information about field of form that will be used by email text template
                        </DialogContentText>
                        {config.form_fields.map((form_field, ind) => (
                            <Paper className={classes.emailConfigUnit}>
                                <Toolbar className={classes.formFieldToolbar}>
                                    <Typography className={classes.commonTitle} color="inherit" variant="subtitle1" component="div">
                                        Form field
                                    </Typography>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={e => {
                                                setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                                    ...conf,
                                                    form_fields: conf.form_fields.filter((field, field_i) => field_i !== ind)
                                                }) : conf))
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Toolbar>
                                <TextField
                                    multiline
                                    margin="dense"
                                    id={`text_field_${ind}__field_name`}
                                    label="Field name"
                                    type="text"
                                    fullWidth
                                    value={form_field['field_name']}
                                    onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                        ...conf,
                                        form_fields: conf.form_fields.map((field, i) => i === ind ? ({
                                            ...field,
                                            field_name: e.target.value
                                        }) : field)
                                    }) : conf))}
                                />
                                {/* <TextField
                                    multiline
                                    margin="dense"
                                    id={`text_field_${ind}__group_name`}
                                    label="Group name"
                                    type="text"
                                    fullWidth
                                    value={form_field['group_name']}
                                    onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                        ...conf,
                                        form_fields: conf.form_fields.map((field, i) => i === ind ? ({
                                            ...field,
                                            group_name: e.target.value
                                        }) : field)
                                    }) : conf))}
                                /> */}
                                <TextField
                                    multiline
                                    margin="dense"
                                    id={`text_field_${ind}__variable_name`}
                                    label="Variable name"
                                    type="text"
                                    fullWidth
                                    value={form_field['variable_name']}
                                    onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                        ...conf,
                                        form_fields: conf.form_fields.map((field, i) => i === ind ? ({
                                            ...field,
                                            variable_name: e.target.value
                                        }) : field)
                                    }) : conf))}
                                />
                            </Paper>
                        ))}
                        <IconButton
                            aria-label="add list"
                            className={classes.addButton}
                            onClick={e => {
                                setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                    ...conf,
                                    form_fields: conf.form_fields.concat({
                                        'variable_name': '',
                                        'field_name': '',
                                        'group_name': ''
                                    })
                                }) : conf))
                            }}
                        >
                            <AddIcon />
                        </IconButton>

                        <DialogContentText className={classes.formFieldsTitle}>
                            Form reassignments
                        </DialogContentText>
                        <DialogContentText>
                            People to reassign the task to
                        </DialogContentText>
                        {config.form_reassignments.map((form_reassignment, ind) => (
                            <Paper className={classes.emailConfigUnit}>
                                <Toolbar className={classes.formFieldToolbar}>
                                    <Typography className={classes.commonTitle} color="inherit" variant="subtitle1" component="div">
                                        Form reassignment
                                    </Typography>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={e => {
                                                setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                                    ...conf,
                                                    form_reassignments: conf.form_reassignments.filter((reassignment, reassignment_i) => reassignment_i !== ind)
                                                }) : conf))
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Toolbar>
                                <TextField
                                    margin="dense"
                                    id={`text_field_${ind}__field_name`}
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    value={form_reassignment['email']}
                                    onChange={e => setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                        ...conf,
                                        form_reassignments: conf.form_reassignments.map((reassignment, i) => i === ind ? ({
                                            ...reassignment,
                                            email: e.target.value
                                        }) : reassignment)
                                    }) : conf))}
                                />
                            </Paper>
                        ))}
                        <IconButton
                            aria-label="add assignment"
                            className={classes.addButton}
                            onClick={e => {
                                setEmailConfig(emailConfig.map((conf, i) => i === index ? ({
                                    ...conf,
                                    form_reassignments: conf.form_reassignments.concat({
                                        'email': ''
                                    })
                                }) : conf))
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Paper>
                ))}
                <IconButton
                    aria-label="add list"
                    className={classes.addButton}
                    onClick={e => {
                        setEmailConfig(emailConfig.concat({
                            'text': '',
                            'type': '',
                            'form_cc_email': '',
                            'subject': '',
                            'approval_choice': '',
                            'close_task': false,
                            'sender_email_field': {
                                'field_name': '',
                                'group_name': ''
                            },
                            'form_fields': [],
                            'form_reassignments': []
                        }))
                    }}
                >
                    <AddIcon />
                </IconButton>

            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={id !== null ? updateForm : saveNewForm} color="primary">
                    {id !== null ? "Update" : "Add"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}