import { Button, Drawer, FormGroup, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Snackbar, TextField, Toolbar, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import useLocalStorage from "react-use-localstorage";
import MuiAlert from '@material-ui/lab/Alert';
import { SETTINGS_URL } from "../../../config/api";
import { useRecoilState } from "recoil";
import { errorState, successState } from "../../../recoil";
import AppBar from "../../parts/AppBar";
import AppSideBar from "../../parts/AppSideBar";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    listItem: {
        textDecoration: null
    },
    formGroup: {
        marginBottom: 46
    },
    textField: {
        marginBottom: 16,
        width: "100%"
    },
    form: {
        width: "100%",
    },
    formGroupTitle: {
        marginBottom: 16
    },
    fromPaper: {
        maxWidth: 580,
        marginLeft: "auto",
        marginRight: "auto",
        padding: "25px 40px"
    },
    logoutButton: {
        position: "absolute",
        right: 20,
        color: "white"
    }
}));

const SETTINGS_SECTIONS = [
    {
        'title': 'Bot',
        'items': [
            {
                'placeholder': 'Bot login',
                'key': 'ADMORTGAGE_BOT_LOGIN'
            },
            {
                'placeholder': 'Bot secret',
                'key': 'ADMORTGAGE_BOT_SECRET'
            }
        ]
    }, 
    // {
    //     'title': 'Office365',
    //     'items': [
    //         {
    //             'placeholder': 'Office365 login',
    //             'key': 'OFFICE365_LOGIN'
    //         },
    //         {
    //             'placeholder': 'Office365 password',
    //             'key': 'OFFICE365_PASSWORD'
    //         }
    //     ]
    // }
]

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default () => {
    const classes = useStyles();
    const [token, setToken] = useLocalStorage('token', null);
    const [settingsValues, setSettingsValues] = useState({})
    const [fetchedFromRemote, setFetchedFromRemote] = useState(false);
    const [error, setError] = useRecoilState(errorState);
    const [success, setSuccess] = useRecoilState(successState);

    useEffect(() => {
        document.title = 'Settings | Admortgage Forms Bot'
    }, [])

    useEffect(() => {
        setSettingsValues(
            Object.fromEntries(
                Object.values(SETTINGS_SECTIONS)
                    .map(value => value.items)
                    .flat()
                    .map(item => [item.key, {
                        ...item, 
                        value: ''
                    }])
            )
        );
    }, [SETTINGS_SECTIONS])


    useEffect(() => {
        if (Object.keys(settingsValues).length === 0 || fetchedFromRemote) {
            return;
        }
        fetch(SETTINGS_URL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                if (!res.ok) throw Error;
                return res.json()
            })
            .then(res => {
                let newSettingsValues = settingsValues;
                res.items.forEach(item => {
                    newSettingsValues[item.key] = item;
                })
                console.log(newSettingsValues)
                setSettingsValues(newSettingsValues);
                setFetchedFromRemote(true);
            })
            .catch(err => {
                console.log(err);
                setError("Failed to fetch settings!")
                setFetchedFromRemote(true);
            })
    }, [settingsValues])

    const saveSettings = (e) => {
        e.preventDefault();
        fetch(SETTINGS_URL, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                items: Object.values(settingsValues)
            })
        })
            .then(res => {
                if (!res.ok) throw Error;
                return res.json();
            })
            .then(res => {
                setSuccess("Successfully updated!");
            })
            .catch(err => {
                console.log(err);
                setError("Error occurred!");
            })
    }


    return (
        <div className={classes.root}>
            <AppBar />
            <AppSideBar activeTab="settings" />
            <main className={classes.content}>
                <Toolbar />
                <Paper className={classes.fromPaper}>
                    <form className={classes.form} onSubmit={saveSettings}>
                        {Object.keys(settingsValues).length !== 0 && SETTINGS_SECTIONS.map(section => (
                            <FormGroup className={classes.formGroup} title={section.title}>
                                <Typography className={classes.formGroupTitle} variant="h4" noWrap>
                                    {section.title}
                                </Typography>
                                {section.items.map(item => (
                                    <div>
                                        <TextField
                                            className={classes.textField}
                                            label={item.placeholder}
                                            value={settingsValues[item.key].value}
                                            onChange={e => {
                                                let newSettingsValues = {...settingsValues};
                                                newSettingsValues[item.key].value = e.target.value;
                                                setSettingsValues(newSettingsValues);
                                            }}
                                        />
                                    </div>
                                ))}
                            </FormGroup>
                        ))}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            SAVE
                        </Button>
                    </form>
                </Paper>
            </main>
        </div>
    )
}