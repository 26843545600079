import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import { useRecoilState } from "recoil";
import FormsPage from "../../pages/FormsPage";
import LoginPage from "../../pages/LoginPage";
import NotFoundPage from "../../pages/NotFoundPage";
import SettingsPage from "../../pages/SettingsPage";
import {errorState, successState} from "../../../recoil";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import WikiPage from "../../pages/WikiPage";
import { MYSELF_URL } from "../../../config/api";

export default () => {
    const [token, setToken] = useLocalStorage('token', null);
    const [error, setError] = useRecoilState(errorState);
    const [success, setSuccess] = useRecoilState(successState);

    useEffect(() => {
        if (token) {    
            fetch(MYSELF_URL, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(res => {
                    if (!res.ok) throw Error(res.status);
                    return res.json();
                })
                .catch(err => {
                    if (err.message == '422') {
                        window.localStorage.removeItem('token');
                        document.location.href = '/';
                    }
                    console.log(err);
                })
        }
        
    }, [])

    return (
        <div className="app-router">
            <Router>
                <Switch>
                    {token ? (
                        <>
                            <Route exact path="/forms" component={FormsPage} />
                            <Route exact path="/settings" component={SettingsPage} />
                            <Route exact path="/reset" component={ResetPasswordPage} />
                            <Route exact path="/wiki/:filePath" component={WikiPage} />
                            <Route exact path="/wiki" component={WikiPage} />
                            <Route exact path="/">
                                <Redirect to="/forms" />
                            </Route>
                        </>
                    ) : (
                        <>
                            <Route exact path="/login" component={LoginPage} />
                            <Route exact path="/">
                                <Redirect to="/login" />
                            </Route>
                        </>
                    )}
                    <Route exact path="/not-found" component={NotFoundPage} />
                    <Route path="/">
                        <Redirect to="/not-found" />
                    </Route>    
                </Switch>
            </Router>
            <Snackbar open={error || success} autoHideDuration={6000} onClose={() => {
                setSuccess(false);
                setError(false);
            }}>
                {success ? (
                    <Alert onClose={() => setSuccess(null)} severity="success">
                        {success}
                    </Alert>
                ) : error ? (
                    <Alert onClose={() => setError(null)} severity="error">
                        {error}
                    </Alert>
                ) : null}
            </Snackbar>
        </div>
    )
}